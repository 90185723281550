// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
    border-radius: 4px;
    border: 1px solid #ffffff;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.8);
}

*::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

*::-webkit-scrollbar-track:window-inactive {
    background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/app/styles.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* styles.css */\r\n*::-webkit-scrollbar {\r\n    width: 8px;\r\n    height: 8px;\r\n}\r\n\r\n*::-webkit-scrollbar-thumb {\r\n    background-color: rgba(100, 100, 100, 0.5);\r\n    border-radius: 4px;\r\n    border: 1px solid #ffffff;\r\n}\r\n\r\n*::-webkit-scrollbar-thumb:hover {\r\n    background-color: rgba(100, 100, 100, 0.8);\r\n}\r\n\r\n*::-webkit-scrollbar-track {\r\n    background-color: #f0f0f0;\r\n}\r\n\r\n*::-webkit-scrollbar-track:window-inactive {\r\n    background-color: #e0e0e0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
